.sign-in-logo {
  padding: 3% 0;
  text-align: center;
  margin: 0 0 -15%;
  img {
    width: 200px;
    margin: 0 0 10px;
  }
  strong {
    display: block;
  }
}
