
.rdrDateDisplayItemActive{
  border-color: rgb(200, 71, 145);
}

.rdrDateDisplayItemActive{
  border-color: rgb(200, 71, 145);
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  border: 1px solid rgb(200, 71, 145);
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
  color: rgb(200, 71, 145) !important;
}

.rdrMonthAndYearPickers select{
  color: rgb(200, 71, 145);
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(200, 71, 145, 0.09);
  border: 0px solid rgb(200, 71, 145);
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: rgb(200, 71, 145);
}

.rdrMonthAndYearPickers select{
  color: rgb(200, 71, 145);
}

.rdrPprevButton i {
  border-color: transparent rgb(200, 71, 145) transparent transparent;
}

.rdrNextButton i {
  border-color: transparent transparent transparent rgb(200, 71, 145);
}

.rdrDayToday .rdrDayNumber span:after{
  background: rgb(200, 71, 145);
}

.rdrDay {
  color: rgb(200, 71, 145)
}
