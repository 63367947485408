html,
body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  height: 100%;
}

#root {
  min-height: 100%;
  background: #f7f8fc;
}
